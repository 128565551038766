import { FC } from 'react'
import { IoExpandOutline, IoLogoAndroid, IoLogoApple } from 'react-icons/io5'
import { useTranslation } from 'react-i18next'

import { storeLinks } from '@expane/logic/links'

export const MobilePage: FC = () => {
  const { t } = useTranslation()

  return (
    <div className="w-screen h-screen flex flex-col justify-center items-center px-8 text-center">
      <IoExpandOutline size="8rem" className="text-primary-700" />
      <p className="mt-8 mb-2 text-main-color text-lg font-medium">
        {t('mobilePage.screenIsTooSmall')}
      </p>
      <p className="text-secondary-color">{t('mobilePage.options')}:</p>

      <div className="flex justify-center gap-8 my-8">
        <a href={storeLinks.ios} className={linkStyle}>
          <IoLogoApple size="1.6rem" />
          App store
        </a>

        <a href={storeLinks.android} className={linkStyle}>
          <IoLogoAndroid size="1.6rem" />
          Google Play
        </a>
      </div>
    </div>
  )
}

const linkStyle =
  'flex gap-1 items-center justify-center text-secondary-color hover:text-gray-500 hover:dark:text-gray-300 focus:text-gray-500 focus:dark:text-gray-300 text-xl underline'
