import { safeWebLocalStorage } from '../safeWebLocalStorage'
import { DEFAULT_LANG } from '@expane/i18n'

export const getWebPreferredUserLanguage = (localStorageKey: string): string => {
  const language = safeWebLocalStorage.getItem(localStorageKey)

  if (!language) {
    const allowedLanguages = ['en', 'uk', 'ru']
    const navigatorLanguage = window.navigator.language.slice(0, 2)

    if (allowedLanguages.includes(navigatorLanguage)) {
      safeWebLocalStorage.setItem(localStorageKey, navigatorLanguage)
      return navigatorLanguage
    }

    // Ukrainian as default
    safeWebLocalStorage.setItem(localStorageKey, DEFAULT_LANG)
    return DEFAULT_LANG
  }

  return language
}
