import {
  EmployeeGroupWithSchedule,
  ServerTimeOffReasonType,
  useCreateTimeOffs,
  useFetchBookingsOfDate,
  useFetchBranchById,
  useFetchCurrentBranchTimezone,
  useFetchEmployeesGroups,
  useFetchTimeOffReasons,
  useFetchTimeOffsOfPeriod,
  WeeklySchedule,
} from '@expane/data'
import { createCurrentDate, DEFAULT_TIMEZONE, zonedTimeToUtc } from '@expane/date'
import { permissions } from '@expane/logic/permission'
import { SCHEDULE_RANGE_MAX, SCHEDULE_RANGE_MIN, SCHEDULE_RANGE_STEP } from '@expane/logic/schedule'
import {
  CloseButton,
  CommonPlaceholderDialogProps,
  Dialog,
  InputLabel,
  Modal,
  PlaceholderInput,
  SelectDropdown,
  usePopupOpenState,
  useShortCut,
  useShowConfirmationPopup,
  useShowWarningPopup,
} from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useFetchMyEmployee, useFetchMyPermissions } from 'gql/employee'
import { useErrorOpeningDialog } from 'logic/hooks/useErrorOpeningDialog'
import { useOpenDialog } from 'logic/hooks/useOpenDialog'
import { observer } from 'mobx-react-lite'
import { FC, RefObject, useRef } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { EditButton } from 'ui/EditButton'
import { Range } from 'ui/Range'
import { RangeDatePicker } from 'ui/RangeDatePicker'
import { TreeMenuItem } from 'ui/TreeMenu'
import { extractItemsFromFolders } from 'ui/TreeMenu/logic.common'
import { convertEmployeeGroupsToTreeItems } from 'ui/TreeMenu/logic.employee'
import { TreeSelect } from 'ui/TreeSelect'
import { SaveButton } from 'widgets/Buttons'
import { TimeOffReasonDialog } from 'widgets/TimeOffReasonDialog'
import {
  getDefaultTimeOffsPeriod,
  getEmployeeIdsWithBookingsByDateTiming,
  transformTimeOffsDataForServer,
} from 'widgets/TimeOffsDialog/logic'
import { defineWorkHours, setTime } from 'widgets/WaitingListDialog/logic'
import {
  checkIsTimeOffReasonAllowedForCreation,
  getTimeOffReasonEmployeeGroupNames,
} from '@expane/logic/timeOff'
import { findById, transformPersonName } from '@expane/logic/utils'
import { getMyEmployeeGroupId } from '@expane/logic/employee'

interface Props {
  closeDialog: () => void
}

const TimeOffsDialog: FC<Props> = observer(props => {
  const modalRef = useRef<HTMLDivElement>(null)

  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: branch, isLoading: isLoadingBranch } = useFetchBranchById(branchId)

  const { data: timeOffReasons, isLoading: isLoadingTimeOffReasons } = useFetchTimeOffReasons()
  const { data: myPermissions, isLoading: isLoadingMyPermissions } = useFetchMyPermissions()
  const { data: myEmployee, isLoading: isLoadingMyEmployee } = useFetchMyEmployee(
    timezone,
    branchId,
  )
  const { data: employeeGroups, isLoading: isLoadingEmployeeGroups } = useFetchEmployeesGroups(
    timezone,
    branchId,
  )

  const isLoading =
    isLoadingTimeOffReasons ||
    isLoadingMyPermissions ||
    isLoadingMyEmployee ||
    isLoadingEmployeeGroups ||
    isLoadingBranch

  const isNoData =
    !timezone || !timeOffReasons || !myPermissions || !myEmployee || !employeeGroups || !branch

  useErrorOpeningDialog(!isLoading && isNoData, props.closeDialog)
  if (!isLoading && isNoData) return null

  return (
    <Modal close={props.closeDialog} ref={modalRef}>
      <Dialog>
        {isLoading ? (
          <TimeOffsDialogPlaceholder closeDialog={props.closeDialog} />
        ) : (
          <TimeOffsDialogLogic
            timeOffReasons={
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              timeOffReasons!
            }
            closeDialog={props.closeDialog}
            myPermissions={
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              myPermissions!
            }
            timezone={timezone ?? DEFAULT_TIMEZONE}
            modalRef={modalRef}
            employeeGroups={
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              employeeGroups!
            }
            branchSchedule={
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              branch!.schedule!
            }
          />
        )}
      </Dialog>
    </Modal>
  )
})

const TimeOffsDialogLogic: FC<{
  timeOffReasons: ServerTimeOffReasonType[]
  myPermissions: string[]
  closeDialog: () => void
  timezone: string
  modalRef: RefObject<HTMLDivElement>
  employeeGroups: EmployeeGroupWithSchedule[]
  branchSchedule: WeeklySchedule
}> = ({
  timeOffReasons,
  closeDialog,
  myPermissions,
  timezone,
  modalRef,
  employeeGroups,
  branchSchedule,
}) => {
  const branchId = store.branch.branchId

  const { t } = useTranslation()

  const { mutateAsync: createTimeOffs } = useCreateTimeOffs()

  const { dialog: timeOffReasonDialog, openCreateDialog: openTimeOffReasonDialog } =
    useOpenDialog(TimeOffReasonDialog)

  const employeeTreeMenuItems = convertEmployeeGroupsToTreeItems(employeeGroups)

  const workHours = defineWorkHours(branchSchedule, createCurrentDate(timezone))

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    setValue,
    watch,
  } = useForm<TimeOffsDialogForm>({
    defaultValues: {
      date: getDefaultTimeOffsPeriod({
        timezone,
      }),
      timing: workHours,
    },
  })

  const watchedPeriod = watch('date')
  const watchedTiming = watch('timing')

  const { data: bookingsOfPeriod } = useFetchBookingsOfDate(watchedPeriod, timezone, branchId)
  const { data: timeOffsOfPeriod } = useFetchTimeOffsOfPeriod(
    zonedTimeToUtc(setTime(watchedPeriod[0], watchedTiming[0]), timezone),
    zonedTimeToUtc(setTime(watchedPeriod[1], watchedTiming[1]), timezone),
    timezone,
  )

  const [openSnackbar] = useSnackbar()
  const { confirmationModal, showConfirmation } = useShowConfirmationPopup()

  const warningContent = (
    <div className="max-w-2xl">
      <p className="mb-2">{t('timeOffs.warning')}</p>

      <div>
        <p>
          {t('employees.name')}:{' '}
          <span className="italic">
            {timeOffsOfPeriod?.map(timeOff => transformPersonName(timeOff.employee)).join(', ')}
          </span>
        </p>
      </div>
    </div>
  )
  const { warningModal, showWarningPopup } = useShowWarningPopup(t('warning'), warningContent)

  const mutateTimeOffs: SubmitHandler<TimeOffsDialogForm> = async ({
    timing,
    timeOffReasonId,
    employees,
    date,
  }) => {
    const extractedEmployees = extractItemsFromFolders(employees)
    const employeeIds = extractedEmployees.map(({ id }) => id)

    const employeeIdsWithBookings = getEmployeeIdsWithBookingsByDateTiming({
      employeeIds,
      bookingsOfPeriod: bookingsOfPeriod ?? [],
      date,
      timing,
    })

    const transformedTimeOffsData = transformTimeOffsDataForServer({
      date,
      timing,
      timeOffReasonId,
      employees: extractedEmployees,
      timezone,
    })

    const timeOffReason = findById(timeOffReasonId, timeOffReasons)
    const myEmployeeGroupId = getMyEmployeeGroupId(store.me.employeeId, employeeGroups)

    const isTimeOffReasonAllowedForCreation = checkIsTimeOffReasonAllowedForCreation(
      timeOffReason,
      myEmployeeGroupId,
    )

    if (!isTimeOffReasonAllowedForCreation) {
      const groupNames = getTimeOffReasonEmployeeGroupNames(timeOffReason)

      openSnackbar(t('timeOff.timeOffReasonError', { name: groupNames }), 'error')

      return
    }

    if (timeOffsOfPeriod?.length) {
      showWarningPopup()
      return
    }

    const onConfirm = async () => {
      const result = await createTimeOffs(transformedTimeOffsData)

      if (result?.insertTimeOffs?.affected_rows) {
        openSnackbar(t('timeOffs.createdSuccess'), 'success')
      } else {
        openSnackbar(t('submitError'), 'error')
      }

      closeDialog()
    }

    if (employeeIdsWithBookings.length) {
      const employeesWithBookingsNames =
        extractedEmployees
          .filter(employee => employeeIdsWithBookings.includes(employee.id))
          .map(employee => employee.name)
          .join(', ') + '.'

      showConfirmation({
        title: t('creating'),
        onConfirm,
        description: `${t('timeOffs.employeesAreBusy', {
          names: employeesWithBookingsNames,
        })} ${t('timeOff.createConfirmation')}`,
      })
    } else onConfirm()
  }

  const isTimeOffReasonEditingAllowed = myPermissions.includes(permissions.timeOffReason.set)

  useShortCut(
    ['Enter'],
    () => {
      handleSubmit(mutateTimeOffs)()
    },
    modalRef.current,
  )

  return (
    <>
      <Dialog.Title>{t('timeOffs.name')}</Dialog.Title>

      <Dialog.Body className="w-148">
        <div className="flex items-center">
          <Controller
            control={control}
            name="employees"
            rules={{ required: true }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TreeSelect
                type="MultiPickMode"
                items={employeeTreeMenuItems}
                onSelected={onChange}
                className="w-1/2 mr-2"
                selected={value as TreeMenuItem[]}
                placeholder={t('placeholders.defaultSelect')}
                label={t('employees.name')}
                errorMessage={{ isShown: Boolean(error), text: t('formError.required') }}
                required
              />
            )}
          />

          <div className="flex items-end w-1/2">
            <Controller
              control={control}
              name="timeOffReasonId"
              rules={{ required: true }}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <SelectDropdown
                  value={value}
                  placeholder={t('placeholders.vacation')}
                  noDataMessage={t('noSuchTimeOff')}
                  onSelectChange={onChange}
                  items={timeOffReasons}
                  disabled={!isTimeOffReasonEditingAllowed}
                  errorMessage={{ isShown: Boolean(error), text: t('formError.required') }}
                  label={t('timeOff.type')}
                  className="w-full"
                  required
                />
              )}
            />
            {isTimeOffReasonEditingAllowed && (
              <EditButton
                onClick={() => openTimeOffReasonDialog(id => setValue('timeOffReasonId', id))}
                className="ml-2 h-9.5 mb-4"
              />
            )}
          </div>
        </div>

        <Controller
          control={control}
          name="date"
          render={({ field: { value, onChange } }) => (
            <RangeDatePicker
              timezone={timezone}
              value={value}
              onChange={onChange}
              label={t('timeOffs.period')}
            />
          )}
        />

        <div className="grow ml-3 mr-2">
          <InputLabel label={t('timeOffs.time')} className={'my-2'} />

          <Controller
            name="timing"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Range
                className="w-full"
                markType="time"
                min={SCHEDULE_RANGE_MIN}
                max={SCHEDULE_RANGE_MAX}
                step={SCHEDULE_RANGE_STEP}
                values={value}
                onChange={onChange}
                labeled
                markSeparators
                placeholderValues={workHours}
              />
            )}
          />
        </div>
      </Dialog.Body>

      <Dialog.Footer>
        <SaveButton onClick={handleSubmit(mutateTimeOffs)} disabled={isSubmitting} />
        <CloseButton onClick={closeDialog} />
      </Dialog.Footer>

      {confirmationModal}
      {timeOffReasonDialog}
      {warningModal}
    </>
  )
}

export const useOpenTimeOffsDialog = () => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const openCreateTimeOffsDialog = () => {
    openPopup()
  }

  const timeOffsDialog = isOpen ? <TimeOffsDialog closeDialog={closePopup} /> : null

  return { openCreateTimeOffsDialog, timeOffsDialog }
}

const TimeOffsDialogPlaceholder: FC<CommonPlaceholderDialogProps> = ({ closeDialog }) => {
  const { t } = useTranslation()

  return (
    <>
      <Dialog.Title>{t('timeOffs.name')}</Dialog.Title>

      <Dialog.Body className="w-148">
        <div className="flex w-full mb-4">
          <PlaceholderInput label={t('employees.name')} className="w-1/2 pr-2" />
          <PlaceholderInput label={t('timeOff.type')} className="w-1/2" />
        </div>

        <PlaceholderInput label={t('timeOffs.period')} className={'w-full'} />

        <InputLabel label={t('timeOffs.time')} className={'my-2'} />

        <Range
          className="w-full"
          markType="time"
          min={SCHEDULE_RANGE_MIN}
          max={SCHEDULE_RANGE_MAX}
          step={SCHEDULE_RANGE_STEP}
          values={[9, 18]}
          placeholderValues={[9, 18]}
          onChange={() => {
            return
          }}
          labeled
          markSeparators
          disabled
        />
      </Dialog.Body>
      <Dialog.Footer>
        <SaveButton disabled />
        <CloseButton onClick={closeDialog} />
      </Dialog.Footer>
    </>
  )
}

type TimeOffsDialogForm = {
  employees: TreeMenuItem[]
  timeOffReasonId: number
  date: [Date, Date]
  timing: number[]
}
