import { InterpolationOptions, Resource } from 'i18next'
import { enUS, format as formatDate, isDate, ru, uk } from '@expane/date'
import jointEnFile from './translations/en.json'
import jointRuFile from './translations/ru.json'
import jointUkFile from './translations/uk.json'

export const DEFAULT_LANG = 'uk'

export const locales = { en: enUS, uk, ru }

type CompatibilityJSON = 'v1' | 'v2' | 'v3' | undefined

interface I18nInitSettings {
  resources: Resource
  lng: string
  keySeparator: '.'
  interpolation: InterpolationOptions
  fallbackLng: readonly string[]
  compatibilityJSON: CompatibilityJSON
}

type LangFile = {
  [key: string]: unknown
}

export const getI18nInitSettings = (options: {
  localLanguage: string
  resources: {
    ukFile: LangFile
    ruFile: LangFile
    enFile: LangFile
  }
  compatibilityJSON?: CompatibilityJSON
}): I18nInitSettings => ({
  compatibilityJSON: options.compatibilityJSON,
  resources: {
    uk: { translation: { ...options.resources.ukFile, ...jointUkFile } },
    ru: { translation: { ...options.resources.ruFile, ...jointRuFile } },
    en: { translation: { ...options.resources.enFile, ...jointEnFile } },
  },
  lng: options.localLanguage,
  keySeparator: '.',
  interpolation: {
    escapeValue: false,
    format: (value, format, lng) => {
      if (isDate(value)) {
        const locale = locales[lng ?? DEFAULT_LANG]
        return formatDate(value, format ?? '', { locale })
      }
      return value
    },
  },
  fallbackLng: ['en', 'uk', 'ru'],
})
