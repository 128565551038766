export const SUPPORT_EMAIL = 'support@expane.pro'
export const SUPPORT_PHONE = '+380 95 108 0108'

export const getExpaneTelegramBotLink = (env: string): string =>
  env === 'development' ? 'https://t.me/expane_dev_bot' : 'https://t.me/expane_bot'

export const getExpaneViberBotLink = (env: string): string =>
  env === 'development' ? 'viber://pa?chatURI=expane' : 'viber://pa?chatURI=expaneBot'

export const getExpaneAdminCabinetLink = (isDev: boolean): string =>
  isDev ? 'https://app-dev.expane.pro' : 'https://app.expane.pro'

export const getExpaneClientCabinetLink = (isDev: boolean): string =>
  isDev ? 'https://dev-client.expane.pro' : 'https://client.expane.pro'

export const storeLinks = {
  ios: 'https://apps.apple.com/ua/app/expane/id1570967413',
  android: 'https://play.google.com/store/apps/details?id=pro.expane.app',
}

export const clientStoreLinks = {
  ios: 'https://apps.apple.com/ua/app/expane/id6698871064',
  android: 'https://play.google.com/store/apps/details?id=pro.expane.client',
}

export const EXPANE_NETWORKS = {
  facebook: 'https://www.facebook.com/profile.php?id=61555005359669',
  instagram: 'https://www.instagram.com/expane.pro',
  telegram: 'https://t.me/expane_pro',
}

export const BUSINESS_SEARCH_PARAMS = {
  branchId: 'brID',
  serviceIds: 'sIDs',
  employeeId: 'eID',
  disableEmployeeSelection: 'dES',
}

interface getBusinessLandingURLParams {
  id: string
  path: string | undefined | null
}

export const getBusinessLandingURL = (
  businessLandingSettings: getBusinessLandingURLParams,
  env: string,
) =>
  (env === 'development' ? 'https://dev-business.expane.pro/' : 'https://business.expane.pro/') +
  (businessLandingSettings.path || businessLandingSettings.id)

// Напевно треба буде винести brID, eID та sIDs в logic
export const getBusinessLandingExtURL = (
  businessLandingSettings: getBusinessLandingURLParams,
  onlineBookingURL: {
    branchId: number
    employeeId?: number | null
    serviceId?: number | null
    disableEmployeeSelection: boolean | null
  },
  env: string,
) => {
  const newSearchParams: Record<string, string> = {}

  newSearchParams[BUSINESS_SEARCH_PARAMS.branchId] = onlineBookingURL.branchId.toString()
  if (onlineBookingURL.serviceId)
    newSearchParams[BUSINESS_SEARCH_PARAMS.serviceIds] = onlineBookingURL.serviceId.toString()
  if (onlineBookingURL.employeeId)
    newSearchParams[BUSINESS_SEARCH_PARAMS.employeeId] = onlineBookingURL.employeeId.toString()
  if (onlineBookingURL.disableEmployeeSelection)
    newSearchParams[BUSINESS_SEARCH_PARAMS.disableEmployeeSelection] = 't'

  return (
    getBusinessLandingURL(businessLandingSettings, env) +
    '/booking?' +
    new URLSearchParams(newSearchParams).toString()
  )
}
